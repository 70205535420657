import React from "react"
import Layout from '../components/layout'
import Image from 'gatsby-image'
import { graphql, Link } from'gatsby'
import { useEffect } from 'react'
import PortableText from '@sanity/block-content-to-react'

export const mainPageData = graphql `
query MyMainPageQuery {
  sanityMain {
    href
    _rawMainEng
    _rawMainFr
    customimage {
      asset {
        fluid {
          ...GatsbySanityImageFluid
        }
      }
    }
  }
  logoType: file(relativePath: { eq: "logo-type5.png" }) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  logoImage: file(relativePath: { eq: "logo-image.png" }) {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;


const Home = ( { data }) => {
    const mainTextData = data;
    const englishText = mainTextData.sanityMain._rawMainEng;
    const frenchText = mainTextData.sanityMain._rawMainFr;

  useEffect(() => {
    const bg = document.querySelector('.index-background');
    const myDiv = document.querySelector('.index-page');
    const myBody = document.querySelector('body');
    let scrolled = function(){
        let dec = window.scrollY / (myBody.scrollHeight);
        return Math.floor(dec * 100);
    }
        window.addEventListener('scroll', () => {
          bg.style.setProperty('background', scrolled() > 2 ? "var(--color-blueDark)" : "var(--color-cream)");
          myDiv.style.setProperty('color', scrolled() > 2 ? "var(--color-cream)" : "var(--color-blueDark)");
        })
  });

  return(
    <div className="index-background">
      <div className="index-page">
      <Layout>

    <div className="index-main-content">
      <div className="index-logo">  
            <Image className="index-logo-type" fluid={data.logoType.childImageSharp.fluid } alt={'gestalt'} />
      </div>

        <div className="index-fr-text">  
            <PortableText
                blocks = { frenchText } 
            />
        </div>
    </div>
      <div className="container">
        <Link to={data.sanityMain.href}>
          <div className="index-image">
             
              <Image fluid={data.sanityMain.customimage.asset.fluid} alt={'gestalt'} />
             
          </div>
          {/*
          <div className="overlay">
            <div className="logo">
             
              <Image fluid={data.logoImage.childImageSharp.fluid } alt={'gestalt'} />
              
              </div>
          </div>
          */}
        </Link>
      </div>

      <div className="index-main-content">
          <div className="index-eng-text"> 
          <PortableText
                blocks = { englishText } 
            />
         </div>
        
      </div>
    </Layout>
    </div>
    </div>
  )
}

export default Home
